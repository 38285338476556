import React from 'react';
import { Link } from 'react-scroll';
import logo from '../images/logo1.png';
import escrow from '../images/escrow.png';








function Navbar() {
    return (
        

        <div>
            
            <nav>
            

                <Link to='main' className='logo' smooth={true} duration={2000}>
                    <img src={logo} alt='logo' />
                </Link>
                 <input className='menu-btn' type='checkbox' id='menu-btn' />
                 <label className='menu-icon' for='menu-btn'>
                        <span className='nav-icon'></span>
                </label>
                <ul className='menu'>
                   <div class="dropdown">
                    <div class="size">
                   

                        <li><Link to='main' className='active' smooth={true} duration={1000}><img src={escrow} alt='escrow' /></Link><div class="dropdown-content">
                        
                            <a href="https://escrow.com">Escrow.com</a>
                            <a href="https://www.escrow.com/support/faqs/how-does-online-escrow-work">How does it work</a>
                            <a href="https://www.escrow.com/protection">Buyer Protection</a>
                            <a href="https://www.escrow.com/domains/domain-concierge">Domain Consierge</a>
                            <a href="mailto:sales@mighty.domains"><i class="fa-solid fa-envelope"></i><span>  </span>Contact us!</a>
                        </div></li></div></div>
               
                        <li>
                        </li>
                        
                </ul>
            </nav>
        </div>
    )
    }


export default Navbar;
