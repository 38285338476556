import React from 'react';






function Box(props) {
    

    return (
        <><div className='s-box'>
            

            <div className='s-b-text1'>
                <h3>Accelerate Your Growth</h3>
                   A strong one-word premium domain name can convey authority and expertise,
                   making it an essential investment for establishing credibility and building trust with readers.  

                   


        </div>

        
        </div>
        
        <div className='s-box2'>
        <td>
                    
                    </td>
                <div className='s-b-text'>
                    <div className='icontitle'>
                <h3><i class="fa-solid fa-charging-station"></i><span>  </span>POWER</h3>
                </div>
                <div>
                    <td>
                        <li>Memorable</li>
                        <li>Brandable</li>
                        <li>Easy Spelling</li>
                        <li>SEO Boost</li>
                        </td>
                    </div>
                    <div>
                    <td>
                    
                    </td>
                    </div>
                    </div>
                    <br></br>
                    <div className='s-b-text'>
                    <div className='icontitle'>
                <h3><i class="fa-solid fa-pepper-hot"></i><span>  </span>HOT</h3>
                </div>
                <div>
                    <td>
                        <li>Innovative</li>
                        <li>New Trend</li>
                        <li>Competitive</li>
                        <li>One Letter</li>
                    </td>
                    </div>
                    <div>
                    <td>
                    
                    </td>
                    </div>
                    </div>
            <br></br>
                    <div className='s-b-text'>
                    <div className='icontitle'>
                <h3><i class="fa-solid fa-shield-halved"></i><span>  </span>ESCROW</h3>
                </div>
                    
                <td>
                        <li>Secure</li>
                        <li>Fast</li>
                        <li>Trusted</li>
                        <li>Licenced</li>
                        </td>
                        
                    </div>
                   
                  
                 


                    
                </div>

                <div className='s-box'>
                    <div className='s-b-text2'>
                <h3><em> "Keep up in the rapidly-evolving world of technology"</em></h3>
                </div>
        </div>
        
        <div className='s-box2'>
        <td>
                    
                    </td>
                <div className='s-b-text'>
                    <div className='icontitle'>
                <h3><i class="fa-solid fa-money-check-dollar"></i><span>  </span>PAYMENT</h3>
                </div>
                <div>
                    <td>
                        <li>Wires</li>
                        <li>Debit</li>
                        <li>PayPal</li>
                        <li>Cards</li>
                        </td>
                    </div>
                    <div>
                    <td>
                    
                    </td>
                    </div>
                    </div>
                    <br></br>
                    <div className='s-b-text'>
                    <div className='icontitle'>
                <h3><i class="fa-solid fa-file-invoice-dollar"></i><span>  </span>BIDDER</h3>
                </div>
                <div>
                    <td>
                        <li>Place Bids</li>
                        <li>Negotiate</li>
                        <li>Private</li>
                        <li>Public</li>
                    </td>
                    </div>
                    <div>
                    <td>
                    
                    </td>
                    </div>
                    </div>
            <br></br>
                    <div className='s-b-text'>
                    <div className='icontitle'>
                <h3><i class="fa-solid fa-building"></i><span>  </span>COMPANY</h3>
                </div>
                    
                <td>
                        <li>International</li>
                        <li>Reliable</li>
                        <li>Build brands</li>
                        <li>24 Support</li>
                        </td>

                        <td>
                    
                    </td>
                        
                    </div>
                   
                  
                 


                    
                </div>


            </>
        
    )
}

export default Box;