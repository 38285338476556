import React from 'react';
import Box from './Box';







export function Specs(props) {
    

    return (
        <div id='specs'>
            <div className='s-heading'>
                <h1> <br></br><strong> </strong></h1>  <p></p><br></br><h2> BID ON ESCROW.COM  <br></br> <em></em> </h2>
                <p></p>
                
            </div>
            <div className='b-container'>
                <Box button='Reach more than 60 "Gulf Regions" worldwide'/>
                
                
                
          </div>
          
          <div className='banner'>
          <a href='https://my.escrow.com/partner.asp?pid=0'><img src='https://secureapi.escrow.com/api/ecart/Content/Images/Affiliate%20Banners/Escow_Banner_265x100.jpg' width='265' height='100' alt='Escrow.com: Buy or Sell Online Without the Fear of Fraud' border='0'></img></a>
          </div>
        
          <div class="copyright">Copyright © 2023 Mighty.Domains • All rights reserved     <br></br><br></br><i class="fa-solid fa-envelope"></i>  sales@mighty.domains</div>
          
        </div>
    )
}

export default Specs;
