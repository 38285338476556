import React from 'react';
import Header from './components/Header';
import Specs from './components/Specs';









function App() {
  return (
    <div className="App">
      
      <Header/>
      <Specs/>
    
      

      
      
    </div>
  );
}

export default App;
