import React from 'react';
import Navbar from './Navbar';
import domainname from '../images/domainname.png';




function Header() {
    return (
        <div id='main'>
            <Navbar/>
            <div className='name'>
           
            </div>
                <br></br>
                <div class="vision">
                  <div class="inner">
                <div class="price1">
                <h2><span>  </span></h2></div><div class="price"><h2><p><span class="metal-flash"><img className='domain_image' src={domainname} alt='domainname' /></span></p></h2>
                </div>
                
              
                <form action="https://www.escrow.com/offer" method="post" target="_blank">
    <input type="hidden" name="type" defaultValue="domain_name" />
    <input
      type="hidden"
      name="non_initiator_email"
      defaultValue="constantin.rauter@gmail.com"
    />
    <input type="hidden" name="non_initiator_id" defaultValue={2530827} />
    <input type="hidden" name="non_initiator_role" defaultValue="seller" />
    <input
      type="hidden"
      name="title"
      defaultValue="the domain name https://x.news"
    />
    <input type="hidden" name="currency" defaultValue="USD" />
    <input type="hidden" name="domain" defaultValue="x.news" />
    <input type="hidden" name="price" defaultValue={0} />
    <input type="hidden" name="concierge" defaultValue="true" />
    <input type="hidden" name="with_content" defaultValue="false" />
    <input type="hidden" name="inspection_period" defaultValue={3} />
    <input type="hidden" name="fee_payer" defaultValue="seller" />
    <input type="hidden" name="return_url" defaultValue="" />
    <input type="hidden" name="button_types" defaultValue="make_offer" />
    <input type="hidden" name="auto_accept" defaultValue="" />
    <input type="hidden" name="auto_reject" defaultValue="" />
    <input
      type="hidden"
      name="item_key"
      defaultValue="123738a0-3e87-11ee-bebd-b564e9325633"
    />
    <button className="escrowbutton" type="submit">
      Make An Offer
    </button>
    <img
      src="https://t.escrow.com/1px.gif?name=bin&price=0&title=the%20domain%20name%20https%3A%2F%2Fx.news&user_id=2530827" alt=""
      style={{ display: "none" }}
    />
  </form>





</div>
</div>
</div>
        
    )
}

export default Header;